.container {
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  margin: 12% 0px;
  align-items: flex-start;
  padding: 20px 0px;
}
.brandText {
  color: var(--light-grey);
}